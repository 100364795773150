var __jsx = React.createElement;
import React from 'react';
var CustomIcon = function CustomIcon(_ref) {
  var icon = _ref.icon;
  if (icon.__typename === 'Image') {
    var url = icon.image.url;
    return __jsx("img", {
      src: url,
      alt: "",
      height: 16
    });
  } else if (icon.__typename === 'Mailchimp') {
    return __jsx("span", {
      dangerouslySetInnerHTML: {
        __html: icon.embedCode
      }
    });
  }
  return icon;
};
export default CustomIcon;